<template>
  <base-missing-data-page :url-img="$options.imageDataMissing" class="clients-missing-data">
    <template v-if="isOwner" #message>
      {{ $t('clients.owner_missing_message') }}
    </template>

    <template v-else-if="isTenants" #message>
      {{ $t('clients.tenants_missing_message') }}
    </template>

    <template v-else #message>
      {{ $t('clients.short_tenants_missing_message') }}
    </template>
  </base-missing-data-page>
</template>

<script>
// Constants
import * as typesClient from '@/constants/typesClients';

// Components
import BaseMissingDataPage from '@/components/BaseMissingDataPage.vue';

import imageDataMissing from '@/assets/imageMissingData/missing-data-clients.svg';

export default {
  name: 'ClientsMissingData',

  components: { BaseMissingDataPage },

  props: {
    typeClient: { type: String, required: true },
  },

  computed: {
    isOwner() {
      return this.typeClient === typesClient.OWNER;
    },
    isTenants() {
      return this.typeClient === typesClient.TENANTS;
    },
  },

  imageDataMissing,
};
</script>
